//import css
import '@splidejs/splide/css/core';
import "./scss/main.scss";

//import components js
import "./components/accordion-item/accordion-item.js";
import "./components/accordion/accordion.js";
import "./components/alert/alert.js";
import "./components/breadcrumb/breadcrumb.js";
import "./components/button/button.js";
import "./components/card-horizontal/card-horizontal.js";
import "./components/card-links/card-links.js";
import "./components/card-news/card-news.js";
import "./components/card/card.js";
import "./components/carousel/carousel.js";
import "./components/dropdown-menu/dropdown-menu.js";
import "./components/editorial-content/editorial-content.js";
import "./components/footer/footer.js";
import "./components/header-top/header-top.js";
import "./components/header/header.js";
import "./components/hero/hero.js";
import "./components/horizontal-slide/horizontal-slide.js";
import "./components/links-right/links-right.js";
import "./components/login/login.js";
import "./components/navbar/navbar.js";
import "./components/page-title/page-title.js";
import "./components/pagination-left/pagination-left.js";
import "./components/pagination/pagination.js";
import "./components/sidebar-left/sidebar-left.js";
import "./components/simple-cta/simple-cta.js";
import "./components/skiplinks/skiplinks.js";
import "./components/splide-slide/splide-slide.js";
import "./components/splide/splide.js";
import "./components/tab-pane/tab-pane.js";
import "./components/tab/tab.js";

import "./js/browser-detect";
import "./js/lazyload";
