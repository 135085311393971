import Splide from '@splidejs/splide';
let slider = document.querySelector('.splide.card-4')
if (slider) {
	new Splide( '.splide.card-4', {
		type   : 'slide',
		omitEnd : true,
		perMove : 1,
		perPage : 1,
		gap : "24px",
		i18n : {
			prev : "Slide precedente",
			next : "Slide successiva",
			first : "Vai alla prima pagina",
			last : "Vai all'ultima pagina",
			pageX: "Vai a pagina %s"
		},
		breakpoints: {
		767: {
			perPage: 2,
		},
		992: {
			perPage: 3,
		},
		2000: {
			perPage: 4,
		},
	  }
	}).mount();
}


